<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 11.9275L5.5575 11.0695C2.21 8.034 0 6.032 0 3.575C0 1.573 1.573 0 3.575 0C4.706 0 5.7915 0.5265 6.5 1.3585C7.2085 0.5265 8.294 0 9.425 0C11.427 0 13 1.573 13 3.575C13 6.032 10.79 8.034 7.4425 11.076L6.5 11.9275Z"
    />
  </svg>
</template>
