export enum TransactionType {
  Credit = "credit",
  Debit = "debit",
}

export enum CampaignState {
  Inactive = "inactive",
  Active = "active",
  Ending = "ending",
  Evaluating = "evaluating",
  Evaluated = "evaluated",
}

// Was tweet posted using the X app or evangelist.gg?
export enum TweetOrigin {
  X = "x",
  Evangelist = "evangelist",
}

/**
 * Used for t_tweet_state.state column
 *
 * See https://www.notion.so/evangelistgg/Post-states-f5a9803557c94a05862afb18d9de6ba8?pvs=4#3510b52f2dea48d98163f991e7a3c337
 */
export enum TweetRewardState {
  // Starting evaluation states
  Evaluating = "evaluating", // From diagram: "Passes basic eligibility (for at least one campaign)"
  Eligible = "eligible", // From diagram: After "semantic evaluation + admin review"
  EligibleBonus = "eligible-bonus", // From diagram: Direct entry state for "tweet with retroactive reward"
  NotEligible = "not-eligible", // From diagram: Tweet fails eligibility checks

  // Timeout states
  Expired = "expired", // From diagram: "not claimed in time (end+7d)"
  ExpiredBonus = "expired-bonus", // From diagram: Timeout state for EligibleBonus

  // User action states
  Claimed = "claimed", // From diagram: User has claimed the reward

  // Final decision states
  Rewarded = "rewarded", // From diagram: "top N + top campaign"
  NotRewarded = "not-rewarded", // From diagram: "< top N or not top campaign"

  // Payment states
  Paid = "paid", // From diagram: "confetti bomb payout XP"
  PaidBonus = "paid-bonus", // From diagram: After claiming from EligibleBonus

  // Terminal state
  Deleted = "deleted", // From diagram: Can happen from any state

  // Note: The "New" and "Manipulated" states from the diagram are missing in this enum
}

/**
 * Used in payload of tweet update from analytics
 */
export enum ReceivedTweetRewardState {
  // User action states
  Claimed = "Claimed", // Same as TweetRewardState but uses PascalCase values
  Discarded = "Discarded", // Only in this enum, similar to Manipulated in the diagram
  Deleted = "Deleted", // Same as TweetRewardState but uses PascalCase values

  // Starting evaluation states
  Evaluating = "Evaluating", // Same as TweetRewardState but uses PascalCase values
  NotEligible = "NotEligible", // Same as TweetRewardState but uses PascalCase values
  Eligible = "Eligible", // Same as TweetRewardState but uses PascalCase values
  EligibleBonus = "EligibleBonus", // Same as TweetRewardState but uses PascalCase without hyphen

  // Timeout states
  Expired = "Expired", // Same as TweetRewardState but uses PascalCase values
  ExpiredBonus = "ExpiredBonus", // Same as TweetRewardState but uses PascalCase without hyphen

  // Final decision states
  NotRewarded = "NotRewarded", // Same as TweetRewardState but uses PascalCase values
  Rewarded = "Rewarded", // Same as TweetRewardState but uses PascalCase values

  // Note: Missing Paid and PaidBonus states compared to TweetRewardState
  // Note: The "New" state from the diagram is missing in this enum
}
