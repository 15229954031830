<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00033 3C4.66699 3 1.82033 5.07333 0.666992 8C1.82033 10.9267 4.66699 13 8.00033 13C11.3337 13 14.1803 10.9267 15.3337 8C14.1803 5.07333 11.3337 3 8.00033 3ZM8.00033 11.3333C6.16033 11.3333 4.66699 9.84 4.66699 8C4.66699 6.16 6.16033 4.66667 8.00033 4.66667C9.84033 4.66667 11.3337 6.16 11.3337 8C11.3337 9.84 9.84033 11.3333 8.00033 11.3333ZM8.00033 6C6.89366 6 6.00033 6.89333 6.00033 8C6.00033 9.10667 6.89366 10 8.00033 10C9.10699 10 10.0003 9.10667 10.0003 8C10.0003 6.89333 9.10699 6 8.00033 6Z"
    />
  </svg>
</template>
