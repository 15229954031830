<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3335 1.33325H2.66683C1.9335 1.33325 1.3335 1.93325 1.3335 2.66659V14.6666L4.00016 11.9999H13.3335C14.0668 11.9999 14.6668 11.3999 14.6668 10.6666V2.66659C14.6668 1.93325 14.0668 1.33325 13.3335 1.33325Z"
    />
  </svg>
</template>
