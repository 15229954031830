<script setup lang="ts">
import InfoStat from "~/components/InfoStat.vue";
import type { MonetaryDto } from "~~/_api_generated_";
import { transformToPercentage } from "~/utils/common";
import type { CampaignListItem, TweetListItem } from "~/common/Types";
import { TweetRewardState } from "evangelist-common/enums";

interface Props {
  tweet: TweetListItem;
  campaign: CampaignListItem & {
    rating: {
      reward: MonetaryDto;
      ratingScore: number | null;
    };
  };
  hideRelevance?: boolean;
}

const props = defineProps<Props>();

const tweet = computed(() => {
  return props.tweet;
});

const state = computed(() => {
  return tweet.value?.state;
});

const selectedCurrency = ref(props.campaign.budgetCurrency);

const campaignCurrencies = computed(() => {
  return prepareCurrencies(props.campaign.rating.reward);
});

const selectedCurrencyAmount = computed(() => {
  return campaignCurrencies.value[selectedCurrency.value];
});

const rewardVisible = computed(() => {
  if (!state.value) {
    return false;
  }
  if (
    [
      TweetRewardState.Evaluating,
      TweetRewardState.NotEligible,
      TweetRewardState.NotRewarded,
      TweetRewardState.Expired,
      TweetRewardState.ExpiredBonus,
    ].includes(state.value)
  ) {
    return false;
  }

  return true;
});

const computedRewardCaption = computed(() => {
  if (!state.value) {
    return "";
  }
  if (
    [
      TweetRewardState.Rewarded,
      TweetRewardState.Paid,
      TweetRewardState.EligibleBonus,
      TweetRewardState.PaidBonus,
    ].includes(state.value)
  ) {
    return "Final Reward";
  }
  return "Estimated Reward";
});

const computedRewardValue = computed(() => {
  if (!state.value) {
    return "";
  }
  if (
    [
      TweetRewardState.Rewarded,
      TweetRewardState.Paid,
      TweetRewardState.EligibleBonus,
      TweetRewardState.PaidBonus,
    ].includes(state.value)
  ) {
    return selectedCurrencyAmount.value;
  }
  return "~" + selectedCurrencyAmount.value;
});
</script>

<template>
  <div class="flex items-center justify-between my-8">
    <TweetState
      v-if="state"
      class="py-1.5 px-3 rounded-3xl"
      :state
      :userTopN="props.campaign?.userTopN ?? null"
    />
    <div v-if="rewardVisible" class="select-gray-wrapper">
      <select v-model="selectedCurrency" class="select-gray">
        <option
          v-for="currency in Object.keys(campaignCurrencies)"
          :key="currency"
          :value="currency"
        >
          {{ currency }}
        </option>
      </select>
    </div>
  </div>

  <div
    v-if="rewardVisible"
    class="flex gap-4 items-center justify-between mb-10"
  >
    <div v-if="!hideRelevance && !campaign.apyBonus" class="flex-auto">
      <InfoStat
        :value="transformToPercentage(campaign.rating.ratingScore ?? 0)"
        :dark-questionmarks="true"
        label="Post Relevance"
        tooltip="Post Relevance is calculated based on alignemnt of the post with this campaign."
      />
    </div>

    <div v-if="campaign.apyBonus" class="flex-auto">
      <InfoStat
        :value="campaign.apyBonus"
        :suffix="'APY'"
        :dark-questionmarks="true"
        label="Yield Boost Bonus"
        :is-apy-bonus="true"
        tooltip="Yield Boost Bonus will be calculated from all verified wallets with the amount staked 1 month before campaign started."
      />
    </div>
    <div v-if="!campaign.apyBonus" class="flex-auto">
      <InfoStat
        v-if="selectedCurrency == 'USD'"
        :dark-questionmarks="true"
        :value="selectedCurrencyAmount"
        :suffix="selectedCurrency"
        :label="computedRewardCaption"
        tooltip="Rewards are calculated based on the post relevance, performance and engagement of creators within this campaign."
      />
      <InfoStat
        v-else
        :value="computedRewardValue"
        :dark-questionmarks="true"
        :suffix="selectedCurrency"
        :label="computedRewardCaption"
        tooltip="Rewards are calculated based on the post relevance, performance and engagement of creators within this campaign."
      />
    </div>
  </div>
</template>
